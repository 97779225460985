<template>
  <div class="newslist">
    <Menu :title="title" />
    
     
    <section>
        <div class="index_news new-wrap list-wrap">
            <ul class="list" 
            v-infinite-scroll="loadmore" 
            infinite-scroll-disabled="noMore"
            infinite-scroll-distance="10" 
            style="height:100vh;overflow-y:auto;padding-top:4rem">
                <div class="item" v-for="item in list" :key="item.id">
                    <router-link :to="{path:'/newsdetail/'+item.id}">
                        <div class="newsImg">
                            <img :src="serveUrl+item.logo">
                        </div>
                        <div class="info">
                            <p class="title moreT2">{{item.title}}</p>
                            <div class="oprate">
                                <p><img src="@/assets/images/date-icon.png" alt="" style="width: 0.75rem;">{{item.createtime|timestampToTime}} </p>
                                <p><img src="@/assets/images/liulan-icon.png" alt="" style="width: 0.93rem;">{{item.views}}</p>
                            </div>
                        </div>
                        
                    </router-link>
                </div>             
            </ul>
            <p v-if="noMore" class="no-more">没有更多了</p>
        </div>
	</section>

    
    
  </div>
</template>
<script>
import Menu from '../components/Menu'
import axios from 'axios'
import {SERVE_URL,PROXY_URL} from '../constant.js'
export default {
    name:'Newslist',
    data() {
      return {
        title:'资讯列表',
        serveUrl:SERVE_URL,
        page:0,
        count:10,
        noMore:false,
        loading:false,
        list:[]

      }
    },
    methods:{
        loadmore() {
            let _this=this;
            if(_this.loading){
                return false;
            }
            _this.loading=true;
            axios.get(PROXY_URL+'/Api/News/newsList', {
                params: {
                    count:_this.count,
                    page:_this.page
                }
            })
            .then(function (response) {
                if( (response.status==200) && (response.data.code==1)){
                    if(_this.list.length){
                        _this.list.concat(response.data.data.list);
                    }else{
                        _this.list=response.data.data.list;
                    }
                    _this.page++;
                    if(response.data.data.list.length<_this.count){
                        _this.noMore=true;
                    }else{
                        console.log(333);
                    }
                    _this.loading=false;
                }else{
                    _this.loading=false;
                    _this.$message.error(response.data.msg);
                }
            })
            .catch(function () {
                _this.loading=false;
                _this.$message.error('后端接口异常');
            });
        }

    },
    computed: {
     
    },
    components: {
        
        Menu
    },
    directives: {

    },
     mounted() {
         this.loadmore();
    }
}
    
</script>
<style>

</style>
