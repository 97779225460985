<template>
  <div class="zhipin">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/serve.jpg" />
            <div class="banner-con">
                <div class="line1">人力资源服务系统</div>
                <div class="line2">客户、人资公司、员工个人一体化信息共享平台。</div>
                <div class="line3"><a href="http://39.103.135.238:7777/login">现在去用</a></div>
            </div>
        </div>
    </div>
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">人力资源服务系统解决方案</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">摆脱海量数据重组、计算，默默地准备好您需要的文档、数据，使结算更简单！</div>
            <div class="l-body">
                <div class="mb1">
                    <img src="@/assets/images/product/serve/bg1.jpg" />
                </div>
                
            </div>
        </div>
	</section>

    <section class="sect">
        <div class="act_x">
            <div>
                <div class="wrap rly_01">
                    <ul>
                        <li class="l-body">
                            
                            
                            <div class="sfl_02_cell sfl_02_txt">
                                <h3 class="p1">待办事宜智能提醒</h3>
                                <p class="p2">
                                    <i>
                                    <span class="circle">•</span>
                                    参保、停保（在职未停保、离职未停保、待离职未停保、投保异常）。
                                    </i>
                                    <i>
                                    <span class="circle">•</span>
                                    合同到期提醒、退休预警提醒。
                                    </i>
                                    <i>
                                    <span class="circle">•</span>
                                    社保规则灵活设置。
                                    </i>
                                </p>
                            </div>
                            <div class="sfl_02_cell sfl_02_img">
                                <img src="@/assets/images/product/serve/bg2.png" alt="">
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">线上结算</h3>
                                    <p class="p2">
                                        <i>根据项目合同，企业只需录入员工工资，系统自动计算社保、个税， 一键生成结算单。</i>
                                        
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img sfl_02_img_wap">
                                    <img src="@/assets/images/product/serve/bg3.png" alt="">
                                </div>
                            
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                              
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">智能薪酬</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        量身定制工资模板，可随时变更，自动同步工资条。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        月度数次发薪自动计算个税，支持新个税累计算法。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        系统一键发薪，跨越多家银行发薪的局限性。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        财务一键报税，让工作更轻松。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        员工手机端查看工资和社保缴纳明细，HR后台统一管理员工工资和社保，信息更透明。
                                        </i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/serve/bg4.png" alt="">
                                </div>
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">智能校验</h3>
                                    <p class="p2">
                                        <i><span class="circle">•</span>支持社保补差、补缴、灵活调动。</i>
                                        <i><span class="circle">•</span>根据实际参保数据与花名册进行自动校对分析，让错参、漏参无处可逃。</i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/serve/bg5.png" alt="">
                                </div>
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">人才库</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        退休、离职人员自动进入人才库储备。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        简历自动储备到人才库。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        人才库简历自动刷新。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        人才搜索。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        内外部推荐裂变人脉，低成本“猎取”高品质人才人才库分级运营，智能匹配推荐人才，闭环激活。
                                        </i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/serve/bg6.png" alt="">
                                </div>
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                               
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">统计分析</h3>
                                    <p class="p2">
                                        <i><span class="circle">•</span>企业一手掌握经营动态。</i>
                                        <i><span class="circle">•</span>结算周期中，管理费以及其他费用各项支出、成本、利润、分类统计。</i>
                                        <i><span class="circle">•</span>全方位了解企业经营数据，助力企业管理。</i>
                                    </p>
                                </div>
                                 <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/serve/bg7.png" alt="">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

   
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Serve',
    data() {
      return {
        title:'人力资源服务系统',
    
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
