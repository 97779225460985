<template>
  <div class="manage">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/serve.jpg" />
            <div class="banner-con">
                <div class="line1">人力资源管理系统</div>
                <div class="line2">区（县）级人社部门的大数据平台。</div>
            </div>
        </div>
    </div>
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">打造区县级人社部门的驾驶舱</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="mb1">
                    <img src="@/assets/images/product/manage/dashuju.png" />
                </div>
                
            </div>
        </div>
	</section>
    <section>
        <div class="act_x" style="padding-bottom:0">
            <div class="act_x_t size-40 color-333 l-title">数智化管理 提升管理效率</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title" style="margin-bottom:0"></div>
            <div class="l-body">
                
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div>
                <div class="wrap rly_01">
                    <ul>
                        <li class="l-body">
                            
                            <div class="sfl_02_cell sfl_02_img">
                                <img src="@/assets/images/product/manage/bg2.png" alt="">
                            </div>
                            <div class="sfl_02_cell sfl_02_txt">
                                <p class="p2" style="    padding-top: 10px;">
                                    <i><span class="circle">•</span>摆脱人力资源管理部门信息收集渠道不畅、耗时长.</i>
                                    <i><span class="circle">•</span>避免重复采集、解决汇总工作量大的困扰。</i>
                                    <i><span class="circle">•</span>建立大数据平台，使管理部门对辖区内人力资源情况一目了然。</i>
                                    <i><span class="circle">•</span>各种数据实时准确、并对数据进行智能统计分析。</i>
                                    <i><span class="circle">•</span>为管理部门统计、调配、决策提供依据。</i>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Manager',
    data() {
      return {
        title:'人力资源管理系统',
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
