<template>
  <div class="empowerment labor">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/empowerment.jpg" />
            <div class="banner-con">
                <div class="line1">产业园赋能</div>
                <div class="line2">平台赋能+政策赋能。</div>
                <div class="line3"><a href="http://www.zhichangcloud.com/parkjiebo/public/">现在去用</a></div>
            </div>
        </div>
    </div>
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">产业园运营</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">搭建平台，共享资源、赋能人力资源企业</div>
            <div class="l-body">
                <div class="yunying-left clearfix">
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="500">
                        <div class="img-container" style="">
                            <img src="@/assets/images/product/funeng/yy1.png">
                        </div>
                        <div class="text-container" style="">
                            <div class="t1" style="">吸引人才  服务人才</div>
                            <div class="t2" style="">为吸引人才、服务人才，柴桑区按照政府、企业合作建的模式，
                                高标准规划建设了柴桑区人力资源服务产业园。
                            </div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="1000">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/yy2.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">配套齐全</div>
                            <div class="t2">园区功能齐全、服务配套，建有展示区、会议室、培训室、办公区。</div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="1500">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/yy3.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">孵化人力资源小微企业</div>
                            <div class="t2">整合人力资源服务资源，孵化人力资源服务小微企业，扶持本土化
                                人力资源服务企业发展壮大。发起成立九江市柴桑区人力资源服务
                                产业协会，带动九江市柴桑区人力资源服务产业协同发展。</div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-left" data-aos-delay="2000">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/yy4.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">立足柴桑  服务九江  服务江西 </div>
                            <div class="t2">产业园以公共就业、高层次人才、社会保障、创
                                业孵化服务等功能为一体，向“立足柴桑
                                区，服务全九江，辐射江西省”为发展愿景奋力迈进。</div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-left" data-aos-delay="2500">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/yy5.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">引才奖励</div>
                            <div class="t2">针对引进的不同层次人才，制定了相对应的配套政策，在落户、个税、住房补贴、奖励、项目孵化、专项基金扶持、创新基金、大师工作站等全方位的鼓励和支持企业引进高层次人才。</div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-left" data-aos-delay="3000">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/yy6.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">杰博专业运营    助力蓬勃发展</div>
                            <div class="t2">园区引进九江杰博为运营机构，通过“职畅云互联网+人力资
                                源服务平台”的“赋能、利他”创新型招商合作模式，推动
                                园区发展。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="l-body bg-f8">
                <div class="p1 ">
                    <img src="@/assets/images/product/funeng/zhanshi.png" />
                </div>
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">“0成本入驻”</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">全方位服务，免房租、免物业、免水电，实现真正的0成本轻松入驻。</div>
            <div class="l-body">
                <div class="jfa_01_c">
                    <ul>
                        <li  data-aos="fade-right" data-aos-delay="300" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/funeng/ruzhu1.png" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">专人对接免费办理营业执照</i>
                                    <i>安心面对员工工伤 和意外风险 。</i>
                                </em>
                            </div>
                        </li>
                        <li  data-aos="fade-right" data-aos-delay="600" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/funeng/ruzhu2.png" class="" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">公章、财务章、法人章免费</i>
                                    <i>（升级自费）</i>
                                </em>
                            </div>
                        </li>
                        <li  data-aos="fade-left" data-aos-delay="900" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/funeng/ruzhu3.png" class="" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">免费办理税务key</i>
                                    <i>（升级自费）</i>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1200" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/funeng/ruzhu4.png" style="">
                            </div>

                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">免费银行开户</i>
                                    <i>（法人到场）</i>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1200" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/funeng/ruzhu5.png" style="">
                            </div>

                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">免费办理人力资源许可证和劳务派遣证</i>
                                    <i>（材料要求齐全）</i>
                                </em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>

    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Empowerment',
    data() {
      return {
        title:'产业园赋能',
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
