<template>
  <div class="newsdetail">
    <Menu :title="title" />
    <div class="new-wrap"  style="padding-top: 4rem;background: rgb(255, 255, 255);margin-top:0">
        <div class="newdetail-con">
          <div class="head">
            <p class="title moreT2">{{info.title}}</p>
            <div class="oprate">
              <p>
                <img src="@/assets/images/date-icon.png" alt="" style="width: 0.75rem;">
                {{info.createtime|timestampToTime}}                    
              </p>
              <p>
                <img src="@/assets/images/liulan-icon.png" alt="" style="width: 0.93rem;">
                {{info.views}}
              </p>
            </div>
          </div>
          <div class="info" v-html="info.content"></div>
        </div>
    </div>

    
  </div>
</template>
<script>
import Menu from '../components/Menu'
import axios from 'axios'
import wxapi from '../utils/wxapi'
import {SERVE_URL,PROXY_URL} from '../constant.js'
export default {
    name:'Newsdetail',
    data() {
      return {
        title:'资讯详情',
        serveUrl:SERVE_URL,
        id:null,
        info:{}
      }
    },
	methods:{
		 wxRegCallback () {
       console.log(this);
		  // 用于微信JS-SDK回调
		  this.wxShareTimeline()
		  this.wxShareAppMessage()
		},
		wxShareTimeline () {
      console.log(this);
		  // 微信自定义分享到朋友圈
		  let option = {
			title: this.info.title, // 分享标题, 请自行替换
			link: window.location.href, // 分享链接，根据自身项目决定是否需要split
			imgUrl: SERVE_URL+this.info.logo, // 分享图标
			success: () => {
			  
			},
			error: () => {
			  
			}
		  }
		  // 将配置注入通用方法
		  wxapi.ShareTimeline(option)
		},
		wxShareAppMessage () {
		  // 微信自定义分享给朋友
      console.log(this);
		  let option = {
			title: this.info.title, // 分享标题, 请自行替换
			desc: this.info.excerpt, // 分享描述, 请自行替换
			link: window.location.href, // 分享链接，根据自身项目决定是否需要split
			imgUrl: SERVE_URL+this.info.logo, // 分享图标
			success: () => {
			  
			},
			error: () => {
			 
			}
		  }
		  // 将配置注入通用方法
		  wxapi.ShareAppMessage(option)
		}
	},
    computed: {
     
    },
    components: {
        
        Menu
    },
    directives: {

    },
    mounted() {
      let _this=this;
      _this.id=_this.$route.params.id;
      axios.get(PROXY_URL+'/Api/News/newsDetail', {
        params: {
            id:_this.id
        }
      })
      .then(function (response) {
        if( (response.status==200) && (response.data.code==1)){
          _this.info=response.data.data.info;
          console.log(_this.info.content);
          _this.info.content =_this.info.content.replace(/src="\/uploads([^"]+)"/gi, 'src="'+SERVE_URL+'/uploads'+'$1"');
          
          if(_this.info.content){
            _this.info.content=_this.info.content.replace(/<img[^>]*>/gi, function (match, capture,pos) {
              if(match.match("style")){
                return match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig,'style="max-width:100%;height:auto"');
              }else{
                var regex1 = new RegExp("(i?)(\<img)(?!(.*?style=['\"](.*)['\"])[^\>]+\>)", 'gmi');
                return match.replace(regex1, '$2 style="max-width:100%;height:auto"$3');
              }
            })
          }
          wxapi.wxRegister(_this.wxRegCallback)
        }else{
          _this.$message.error(response.data.msg);
        }
        
      })
      .catch(function () {
          _this.$message.error('后端接口异常111');
      });
      //wxapi.wxRegister(this.wxRegCallback)
    }
}
    
</script>
<style>

</style>
