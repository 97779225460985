<template>
  <div class="econtract">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/empowerment.jpg" />
            <div class="banner-con" style="left: 0;width: 100%;text-align: center;">
                <div class="line1">职畅云生态合作伙伴</div>
                <div class="line2">与合作伙伴一起打造共赢HR生态圈。</div>
                <div class="line3">
                <router-link :to="{path:'/jiameng'}">
                  立即成为合作伙伴
                </router-link>
                </div>
            </div>
        </div>
    </div>
    <section>
    
      <div class="index-partner">
        <ul v-infinite-scroll="loadmore" 
          infinite-scroll-disabled="noMore"
          infinite-scroll-distance="10" 
          style="height:100vh;overflow-y:auto;padding-top:1rem">
          <li v-for="item in list" :key="item.id">
            <img :src="serveUrl+item.image" />
          </li>
        </ul>
      </div>
    
  </section>
  </div>
</template>
<script>
import Menu from '../components/Menu'
import ProductBanner from '../components/ProductBanner'
import axios from 'axios'
import {SERVE_URL,PROXY_URL} from '../constant.js'
export default {
    name:'Partner',
    data() {
      return {
        title:'合作客户',
        serveUrl:SERVE_URL,
        page:0,
        count:20,
        noMore:false,
        loading:false,
        list:[]
      }
    },
    methods:{
      loadmore() {
        let _this=this;
        if(_this.loading){
          return false;
        }
        _this.loading=true;
        axios.get(PROXY_URL+'/Api/Index/partnerList', {
            params: {
                count:_this.count,
                page:_this.page
            }
        })
        .then(function (response) {
            if( (response.status==200) && (response.data.code==1)){
                if(_this.list.length){
                    _this.list.concat(response.data.data.list);
                }else{
                    _this.list=response.data.data.list;
                }
                _this.page++;
                if(response.data.data.list.length<_this.count){
                    _this.noMore=true;
                }
                _this.loading=false;
            }else{
                _this.loading=false;
                _this.$message.error(response.data.msg);
            }
        })
        .catch(function () {
            _this.loading=false;
            _this.$message.error('后端接口异常');
        });
      }
    },
    computed: {
     
    },
    components: {
        Menu,
        ProductBanner
    },
    directives: {

    },
     mounted() {
      this.loadmore();
    }
}
    
</script>
<style>

</style>
