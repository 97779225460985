<template>
  <div class="econtract demon">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/demon.jpg" />
        </div>
    </div>
    <div class="sub-info l-body">
        <div class="item">
            <router-link class="inner" :to="{path:'/apply/1'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/zhipin.png" alt="JOB直聘" title="JOB直聘">
                </div>
                <h3>
                    JOB直聘
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>

        <div class="item">
            <router-link class="inner" :to="{path:'/apply/2'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/serve.png" alt="人力资源服务系统" title="人力资源服务系统">
                </div>
                <h3>人力资源服务系统</h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>

        <div class="item">
            <router-link class="inner" :to="{path:'/apply/3'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/manage.png" alt="人力资源管理系统" title="人力资源管理系统">
                </div>
                <h3>
                    人力资源管理系统
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>

        <div class="item">
            <router-link class="inner" :to="{path:'/apply/4'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/hetong.png" alt="电子合同" title="电子合同">
                </div>
                <h3>
                    电子合同
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>


        <div class="item">
            <router-link class="inner" :to="{path:'/apply/5'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/flexible.png" alt="灵活用工" title="灵活用工">
                </div>
                <h3>
                    灵活用工
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>

        <div class="item">
            <router-link class="inner" :to="{path:'/apply/6'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/ygbao.png" alt="用工宝" title="用工宝">
                </div>
                <h3>
                    用工宝
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>


        <div class="item">
            <router-link class="inner" :to="{path:'/apply/7'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/funeng.png" alt="产业园赋能" title="产业园赋能">
                </div>
                <h3>
                    产业园赋能

                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>


        <div class="item">
            <router-link class="inner" :to="{path:'/apply/8'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/educate.png" alt="教育培训" title="教育培训">
                </div>
                <h3>
                    教育培训
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>
        <div class="item">
            <router-link class="inner" :to="{path:'/apply/9'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/yundanbao.png" alt="云担保" title="云担保">
                </div>
                <h3>
                    云担保
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>
        <div class="item">
            <router-link class="inner" :to="{path:'/apply/10'}" >
                <div class="icon">
                    <img src="@/assets/images/nav-header/others.png" alt="其他服务" title="其他服务">
                </div>
                <h3>
                    其他服务
                </h3>
                <div class="apply">预约演示</div>
            </router-link>
        </div>
    </div>
    
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Demon',
    data() {
      return {
        title:'在线演示'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
