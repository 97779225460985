<template>
  <div>
      <header id="header_box">
          <div id="header">
              <div class="header_box">
                  <a class="logo-container">
                      <img class="logo_img" src="@/assets/images/logo.png" alt="logo_img">
                  </a>
                  <div class="logo_text">
                      <span class="size-20 color-999">一站式人力资源服务平台</span> 
                  </div>
              </div>
              <img class="menu_img menu" @click="showMenu"  src="@/assets/images/menu.png" alt="menu_img">
          </div>
    </header>
    <section id="banner" class="index-banner">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide class="main-slide">
              <img style="width:100%" src="@/assets/images/banner/1.jpg" />
              <div class="slide-con" style="">
                  <div class="line1" style="color:#fff;">
                      打造数字化
                  </div>
                  <div class="line1" style="color:#fff;">人力资源服务链</div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <a href="https://www.jobzhipin.com">
                <img style="width:100%" src="@/assets/images/banner/2.jpg" />
                <div class="slide-con">
                    <div class="line1">
                        <div class="icon-cont" style="">
                            <div class="icon-cont-con" style="">
                                <img  src="@/assets/images/banner/job-icon.png" />
                            </div>
                        </div>
                        JOB直聘
                    </div>
                    <div class="line1">更高效的人才全流程管理</div>
                    <div class="blank2"></div>
                </div>
              </a>
            </swiper-slide>
            <swiper-slide>
              <a href="http://39.103.135.238/login.html">
                <img style="width:100%" src="@/assets/images/banner/3.jpg" />
                <div class="slide-con">
                  <div class="line1">
                      <div class="icon-cont">
                          <div class="bgffb900 icon-cont-con">
                              <img src="@/assets/images/banner/fuwu-icon.png" />
                          </div>
                      </div>
                      人力资源服务系统
                  </div>
                  <div class="line1">一体化人力资源运营方式</div>
                  <div class="blank2"></div>
                  
                </div>
              </a>
              
            </swiper-slide>
            <div class="swiper-pagination banner-pagination" slot="pagination"></div>
        </swiper>  
    </section>
    <section class="rlzy">
      <div class="index_market_box">
        <div class="size-40 color-333 text-center">互联网+人力资源服务平台</div>
        <div class="title_down_line"></div>
        <div class="index_market">
          <swiper>
            <swiper-slide>
              <div class="index_market_item">
                <router-link :to="{path:'/zhipin'}">
                  <img class="index_market_img" src="@/assets/images/nav-header/zhipin.png" alt="">
                  <h2 class="size-12 color-333">JOB直聘</h2>
                </router-link> 
                
              </div>
              <div class="index_market_item">
                <router-link :to="{path:'/serve'}">
                  <img class="index_market_img" src="@/assets/images/nav-header/serve.png" alt="">
                  <h2 class="size-12 color-333">人力资源服务系统</h2>
                </router-link> 
              </div>
              <div class="index_market_item">
                <router-link :to="{path:'/econtract'}"> 
                  <img class="index_market_img" src="@/assets/images/nav-header/hetong.png" alt="">
                  <h2 class="size-12 color-333">电子合同</h2>
                </router-link> 
              </div>
              <div class="index_market_item">
              <router-link :to="{path:'/flexible'}">  
                <img class="index_market_img" src="@/assets/images/nav-header/flexible.png" alt="">
                <h2 class="size-12 color-333">灵活用工</h2>
              </router-link>
              </div>
              <div class="index_market_item">
                <router-link :to="{path:'/manager'}"> 
                  <img class="index_market_img" src="@/assets/images/nav-header/manage.png" alt="">
                  <h2 class="size-12 color-333">人力资源管理系统</h2>
                </router-link>
              </div>
              
              
              <div class="index_market_item">
                <router-link :to="{path:'/labor'}">
                  <img class="index_market_img" src="@/assets/images/nav-header/ygbao.png" alt="">
                  <h2 class="size-12 color-333">用工宝</h2>
                </router-link> 
              </div>
              <div class="index_market_item"> 
                <router-link :to="{path:'/empowerment'}">
                  <img class="index_market_img" src="@/assets/images/nav-header/funeng.png" alt="">
                  <h2 class="size-12 color-333">产业园赋能</h2>
                </router-link> 
              </div>
              <div class="index_market_item">
                <router-link :to="{path:'/educate'}"> 
                  <img class="index_market_img" src="@/assets/images/nav-header/hetong.png" alt="">
                  <h2 class="size-12 color-333">教育培训</h2>
                </router-link>  
              </div>
              <!--<div class="index_market_item" style="float:none;">
                <router-link :to="{path:'/danbao'}">  
                  <img class="index_market_img" src="@/assets/images/nav-header/yundanbao.png" alt="">
                  <h2 class="size-12 color-333">云担保</h2>
                </router-link> 
				-->
				<div class="index_market_item" style="float:none;">
				<a href="http://www.zhichangcloud.com/exam/">
					<img class="index_market_img" src="@/assets/images/nav-header/kaoshi.png" alt="">
					<h2 class="size-12 color-333">考试中心</h2>
				</a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
            
          
        
      </div>
		</section>
    <section class="index-newswrper">
      <div class="index_market_box">
        <div class="size-40 color-333 text-center">资讯</div>
        <div class="title_down_line " ></div>
        <div class="index_news new-wrap list-wrap">
          <ul class="list">
            <div class="item" v-for="item in newslist" :key="item.id">
              <router-link :to="{path:'/newsdetail/'+item.id}">
                <div class="newsImg">
                  <img :src="serveUrl+item.logo">
                </div>
                <div class="info">
                  <p class="title moreT2">{{item.title}}</p>
                  <div class='jianjie'>{{item.excerpt}}</div>
                  <div class="oprate">
                      <p><img src="@/assets/images/date-icon.png" alt="" style="width: 0.75rem;">{{item.createtime|timestampToTime}} </p>
                      <p><img src="@/assets/images/liulan-icon.png" alt="" style="width: 0.93rem;">{{item.views}}</p>
                  </div>
                </div>
               
              </router-link>
            </div>       
          </ul>

          
        </div>
        <div class="view_more_btn mt-100">
          <router-link :to="{path:'/newslist'}">查看更多>></router-link>
        </div>
      </div>
		</section>
    <!--产业园展示-->
    <section>
      <div class="index_market_box zhanshi-wrapper">
        <div class="size-40 color-333 text-center">产业园展示</div>
        <div class="title_down_line"></div>
        <div class="index-zhanshi" style="">
          <swiper class="swiper_dashujv swiper-container-horizontal" ref="mySwiper1" :options="swiperOptions1">

            <swiper-slide v-for="item in zhanshilist" :key="item.id">
            <img style="width:100%" :src="serveUrl+item.image" />
            </swiper-slide>
            <div class="swiper-pagination park-pagination" slot="pagination"></div>
          </swiper>  
        </div>
      </div>
		</section>

    <!--合作伙伴-->
    <section>
      <div class="index_market_box">
        <div class="size-40 color-333 text-center">合作伙伴</div>
        <div class="title_down_line"></div>
        <div class="index-partner">
          <ul>
            <li v-for="item in partnerlist" :key="item.id">
              <img :src="serveUrl+item.image" />
            </li>
          </ul>
        </div>
        <div class="view_more_btn mt-100">
						<router-link :to="{path:'/partner'}">查看更多>></router-link>
				</div>
      </div>
		</section>
    <!--static-->
    <section>
      <div class="openEre_box ptb-100">
        <div class="" style="display:flex;margin-bottom:1rem;"> 
           <div class="openEre_cont">
              <div class="openEre_num"><span>200</span><span>+</span><span></span></div>
              <span>服务企业数量</span> 
            </div>
            <div class="openEre_cont">
              <div class="openEre_num"><span>10</span><span>万</span><span>+</span></div>
              <span>服务员工数量</span> 
            </div>
            <div class="openEre_cont">
              <div class="openEre_num"><span>30</span><span></span><span>+</span></div>
              <span>赋能同行</span> 
            </div>
        </div>
        <div class="size-50 color-white text-center" style="font-size:1rem;line-height:2">选择职畅云</div>
        <div class="size-15 color-white text-center" style="font-size:1rem;">拥有领先的数字化人力资源服务</div>
        
        
       
      </div>
		</section>
    <Bottom />
    <!-- 侧边菜单栏 -->
    <div  class="dh_menu" :class="menuShow?'show':''">
      <div class="side-shade" @click="hideMenu"></div>
      <div class="sidewrap">
        <ul>
         <li>
            <router-link :to="{path:'/'}" class="ico1">
              <img src="@/assets/images/menu/home-icon.png">
              首页
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/product'}" class="ico2">
              <img src="@/assets/images/menu/product-icon.png" />
              产品服务
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/demon'}" class="ico2">
              <img src="@/assets/images/menu/yanshi-icon.png" />
              在线演示
            </router-link>
          </li>
          
          <li>
            <router-link :to="{path:'/partner'}" class="ico1">
              <img src="@/assets/images/menu/kehu-icon.png" />
              合作客户
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/about'}" class="ico1">
              <img src="@/assets/images/menu/contact-icon.png" />
              联系我们
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/jiameng'}" class="ico2">
              <img src="@/assets/images/menu/jiameng-icon.png" />
              合作加盟
            </router-link>
          </li>   
        </ul>
      </div>
    </div>
    <!-- 侧边菜单栏end-->
  </div>
</template>
<script>
import wxapi from '../utils/wxapi'
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import axios from 'axios'
import { Message } from 'element-ui'
import Bottom from '../components/Bottom'
import {SERVE_URL,PROXY_URL} from '../constant.js'
import 'swiper/css/swiper.css'
export default {
    name:'Index',
    data() {
      return {
        serveUrl:SERVE_URL,
        menuShow:false,
        newslist:[],
        partnerlist:[],
        zhanshilist:[],
        swiperOptions: {
          pagination: {
            el: '.banner-pagination'
          },
          //autoplay:true,
          loop:true,
          // Some Swiper option/callback...
        },
        swiperOptions1: {
          slidesPerView: 2,
          initialSlide:2,
          spaceBetween: 0,
          loop:true,
          autoplay:true,
          centeredSlides:true,
            pagination: {
                 el: '.park-pagination',
                 clickable: true,
             },
          // Some Swiper option/callback...
        }
      }
    },
    methods:{
      showMenu(){
        this.menuShow=true;
      },
      hideMenu(){
        this.menuShow=false;
      },
	  wxRegCallback () {
	  // 用于微信JS-SDK回调
	  this.wxShareTimeline()
	  this.wxShareAppMessage()
	},
	wxShareTimeline () {
	  // 微信自定义分享到朋友圈
	  let option = {
		title: '江西职畅云信息科技有限公司_人才招聘、人力资源服务系统等SaaS一体化云平台', // 分享标题, 请自行替换
		link: window.location.href, // 分享链接，根据自身项目决定是否需要split
		imgUrl: SERVE_URL+'/assets/index/images/share-logo.jpg', // 分享图标
		success: () => {
		},
		error: () => {
		}
	  }
	  // 将配置注入通用方法
	  wxapi.ShareTimeline(option)
	},
	wxShareAppMessage () {
	  // 微信自定义分享给朋友
	  let option = {
		title: '江西职畅云信息科技有限公司', // 分享标题, 请自行替换
		desc: '人才招聘、人力资源服务系统等SaaS一体化云平台', // 分享描述, 请自行替换
		link: window.location.href, // 分享链接，根据自身项目决定是否需要split
		imgUrl: SERVE_URL+'/assets/index/images/share-logo.jpg', // 分享图标
		success: () => {
		},
		error: () => {
		}
	  }
	  // 将配置注入通用方法
	  wxapi.ShareAppMessage(option)
	}
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      },
      swiper1(){
        return this.$refs.mySwiper1.$swiper
      }
    },
    components: {
        Swiper,
        SwiperSlide,
        Bottom
    },
    directives: {

    },
    mounted() {
      let _this=this;
      axios.get(PROXY_URL+'/Api/Index/index', {
        params: {
        }
      })
      .then(function (response) {
        if( (response.status==200) && (response.data.code==1)){
          _this.newslist=response.data.data.newslist;
          _this.partnerlist=response.data.data.partnerlist;
          _this.zhanshilist=response.data.data.zhanshilist;
        }else{
          Message({
            message: response.data.msg,
            type: 'error'
          })
        }
      })
      .catch(function () {
        Message({
          message: '网络异常',
          type: 'error'
        })
      });
      wxapi.wxRegister(this.wxRegCallback);
    }
}
    
</script>
<style>

</style>
