<template>
  <div class="othersevre">
    <Menu  :title="title" />
    <ProductBanner />

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">共享用工</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">区域内劳动力动态调配，提高人力使用效率，节约企业成本，同时解决季节性临时用工难题。</div>
            <div class="l-body">
                <div class="p1 ">
                    <img src="@/assets/images/product/others/share-work.png" />
                </div>
            </div>
        </div>
	</section>
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">薪资代发</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">一键发薪 快速到账，省时、省力、安全的薪资代发服务。</div>
            <div class="l-body">
                <div class="yunying-left clearfix">
                    <div class="yunying-item " data-aos="fade-right" data-aos-delay="500">
                        <div class="img-container" style="">
                            <img src="@/assets/images/product/others/daifa-icon1.png">
                        </div>
                        <div class="text-container" style="">
                            <div class="t1" style="">支持日/周/月薪代发</div>
                            <div class="t2" style="">员工弹性领薪，满足个性化需求，7×24小时 ，降低员工 离职率！</div>
                        </div>
                    </div>
                    <div class="yunying-item " data-aos="fade-right" data-aos-delay="1000">
                        <div class="img-container">
                            <img src="@/assets/images/product/others/daifa-icon2.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">独立安全发薪专户</div>
                            <div class="t2">企业自有账户，平台全程不碰资金。</div>
                        </div>
                    </div>
                    <div class="yunying-item " data-aos="fade-right" data-aos-delay="1500">
                        <div class="img-container">
                            <img src="@/assets/images/product/others/daifa-icon3.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">一键上传发薪文件</div>
                            <div class="t2">简化传统发薪流程，文件模板化，多次审核，安全 存储， 确保发薪准确。</div>
                        </div>
                    </div>
                    <div class="yunying-item " data-aos="fade-left" data-aos-delay="2000">
                        <div class="img-container">
                            <img src="@/assets/images/product/others/daifa-icon4.png">
                        </div>
                        <div class="text-container">
                            <div class="t1">数据权限隔离</div>
                            <div class="t2">为老板/人事/财务设定不同权限，提供多重支付校验模式 选择，确保支付安全、数据安全。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">薪资预支</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">为员工解决因特殊情况出现经济周转困难，让员工工作、生活无后顾之忧。</div>
            <div class="l-body bg-f8">
                <div class="p1 ">
                    <img src="@/assets/images/product/others/yuzhi-bg.png" />
                </div>
            </div>
        </div>
	</section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import ProductBanner from '../components/ProductBanner'
import Menu from '../components/Menu'
export default {
    name:'Otherserve',
    data() {
      return {
        title:'其它服务'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        ProductBanner,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
