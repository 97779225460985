<template>
  <div class="educate">
    
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/educate.jpg" />
            <div class="banner-con">
                <div class="line1">教育培训</div>
                <div class="line2">学历提升、招考面试、管理提升学习及培训平台。</div>
				<!--<div class="line3"><a href="http://www.zhichangcloud.com/exam/">现在去用</a></div>-->
            </div>
        </div>
    </div>
    <section v-if="laiyuan==6">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">国家机关和事业单位岗位招录人员面试培训</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
               <div class="divi"><img src="@/assets/images/product/educate/shiyedw/bg1.png" style=""></div>
               
               <div class="divi"><img src="@/assets/images/product/educate/shiyedw/bg2.png" style=""></div>
            </div>
        </div>
	</section>
    <XueliForm :type="type" :laiyuan="laiyuan" />


    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
import XueliForm from '../components/XueliForm'
export default {
    name:'PeixunDetails',
    data() {
      return {
        title:'培训报名',
        type:0,
        laiyuan:0
      }
    },
    computed: {
     
    },
    watch:{
    },
    components: {
        Bottom,
        Menu,
        XueliForm
    },
    directives: {

    },
     mounted() {
         this.laiyuan=this.$route.params.laiyuan;
    }
}
    
</script>
<style scoped>
    .divi{
        padding:.5rem 0
    }
    .divt{
        text-indent:2rem;
        text-align:left
    }
</style>
