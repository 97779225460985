<template>
  <div class="educate">
    
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/educate.jpg" />
            <div class="banner-con">
                <div class="line1">教育培训</div>
                <div class="line2">学历提升、招考面试、管理提升学习及培训平台。</div>
				<!--<div class="line3"><a href="http://www.zhichangcloud.com/exam/">现在去用</a></div>-->
            </div>
        </div>
    </div>
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">学历教育</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
               <div class="edu-list clearfix">

                    <div class="edu-item " data-aos="fade-left" data-aos-delay="1200">
                        <router-link :to="{path:'/xuelibaoming/1'}" class="ico2">
                            <div class="imgdiv">
                                <img src="@/assets/images/product/educate/huadong/bg1.png" style="">
                            </div>
                            <div class="edu-title" style="">华东交通大学</div>
                            <div class="edu-button" style="">查看详情</div>
                        </router-link>
                    </div>
                    <div class="edu-item " data-aos="fade-right" data-aos-delay="300">
                        <router-link :to="{path:'/xuelibaoming/2'}" class="ico2">
                            <div class="imgdiv">
                                <img src="@/assets/images/product/educate/zhida/bg1.png" style="">
                            </div>
                            <div class="edu-title" style="">九江职业技术学院</div>
                            <div class="edu-button" style="">查看详情</div>
                        </router-link>
                    </div>

                    <div class="edu-item " data-aos="fade-right" data-aos-delay="600">
                        <router-link :to="{path:'/xuelibaoming/3'}" class="ico2">
                            <div class="imgdiv">
                                <img src="@/assets/images/product/educate/shida/bg1.png" style="">
                            </div>
                            <div class="edu-title" style="">江西师范大学</div>
                            <div class="edu-button" style="">查看详情</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
	</section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'XueliE',
    data() {
      return {
        title:'学历教育',
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
