<template>
  <div class="copyRight">
    <div class="floorLink">
      <p class="floorLink-p">
        <span><router-link :to="{path:'/about'}">联系我们</router-link><i></i></span>
        <span><router-link :to="{path:'/newslist'}">行业资讯</router-link></span>
      </p>
    </div>
    <p>Copyright © {{nowYear}} {{companyName}}<span></span></p>
    <p class="pline">联系电话:{{zuoji}}</p>
    <p class="pline">{{icpBeian}}</p>
    <p class="pline">{{icpAnbei}}</p>
  </div>
</template>

<script>
import {ICP_BEIAN,ZUOJI,COMPANY_NAME,EMAIL,ICP_ANBEI} from '../constant.js'
export default {
  name: 'Bottom',
  props: {
  },
  data(){
    return {
      icpBeian:ICP_BEIAN,
	  icpAnbei:ICP_ANBEI,
      zuoji:ZUOJI,
      companyName:COMPANY_NAME,
      email:EMAIL
    }
  },
  computed:{
    nowYear(){
      let date = new Date()
      let y = date.getFullYear()
      return y;
    }
  }
}
</script>
<style scoped></style>
