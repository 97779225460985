<template>
  <div class="econtract bg-f8">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/about.jpg" />
        </div>
    </div>

    <div class="about-wrap">
        <div class="about-con1 about-con">
            <div class="info">
                <p class="title">{{companyName}}</p>
                <em></em>
                <p class="intro">
                JOB平台结合柴桑数字经济，坚持数字化、科技化、智能化的发展路线，坚持“利他、赋能”的原则，嫁接人力资源SaaS平台，人才招聘、劳务外包、劳务派遣、人事代理、社保薪酬福利及人力资源衍生业务等系统，给人力资源同行带来技术赋能、效率提升、提高了服务竞争力。“互通、赋能、共享、共赢”是平台发展的核心动力，JOB平台与时俱进，能够解决行业痛点的新产品、新项目，平台下的JOB招聘小程序、人事管理、电子合同、社保管理、薪税管理、支付管理、共享用工等模块，为智慧化、数字化人力资源服务注入了新动能。
                </p>
            </div>
        </div>
        <div class="about-con3 about-con">
            <div class="info"><p class="title">联系我们</p><em></em></div>
            <div class="contact">
                <div class="concact-t">
                    <div class="concact-l"><img src="@/assets/images/qrcode.jpg" alt=""></div>
                    <div class="concact-r">
                        <p><span>联系</span>：{{linkname}}</p>
                        <p><span>座机</span>：{{zuoji}}</p>
                        <p><span>邮箱</span>：{{email}}</p>
                        <p><span>地址</span>：{{address}}{{addressSub}}</p>
                        
                    </div>
                </div>
                <div class="concact-b">
                    <img src="@/assets/images/phone1.png" alt="">
                    <div class="phone-num">
                        <p>{{zuoji}}</p>
                        <span>周一至周五 9：00-18：00</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
import {LINKNAME,ZUOJI,EMAIL,ADDRESS,ADDRESS_SUB,COMPANY_NAME} from '../constant.js'
export default {
    name:'About',
    data() {
      return {
        title:'关于我们',
        linkname:LINKNAME,
        zuoji:ZUOJI,
        email:EMAIL,
        address:ADDRESS,
        addressSub:ADDRESS_SUB,
        companyName:COMPANY_NAME
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
