<template>
  <div class="zhipin">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/job.jpg" />
            <div class="banner-con">
                <div class="line1">JOB直聘</div>
                <div class="line2">便捷、高效，使求职、招聘更简单。</div>
                <!--<div class="line3"><a href="https://www.jobzhipin.com/">现在去用</a></div>-->
                <div class="line3"><a href="javascript:;" v-on:click="goZhipin()">现在去用</a></div>
                
            </div>
        </div>
    </div>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">JOB直聘核心亮点</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">全流程数智化管理，缩短1/2的招聘周期</div>
            <div class="l-body">
                <ul class="clearfix hxld">
                    <li data-aos="fade-left" data-aos-delay="300" class="clearfix" >
                        <div class="img-container">
                            <img src="@/assets/images/product/zhipin/jianli.png">
                        </div>
                        <div class="text-container">
                            <h3><span>电子简历</span></h3>
                            <p>在线生成电子简历，实时更新、一键推送</p>
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="600" class="clearfix" >
                        
                        <div class="text-container fleft">
                            <h3><span>能岗匹配</span></h3>
                            <p>根据各人求职意向，自动匹配符合要求的岗位，精准推送</p>
                        </div>
                        <div class="img-container fright">
                            <img src="@/assets/images/product/zhipin/pipei.png">
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="900"  class="clearfix">
                        <div class="img-container">
                            <img src="@/assets/images/product/zhipin/goutong.png">
                        </div>
                        <div class="text-container">
                            <h3><span>即时沟通</span></h3>
                            <p>打开小程序，发现感兴趣的岗位或应聘者，随时沟通</p>
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="1200"  class="clearfix">
                         <div class="text-container fleft">
                            <h3><span>线上招聘会</span></h3>
                            <p>
                                面试不受地域和时间的限制，随时随地发起面试邀请。
                            </p>
                        </div>
                        <div class="img-container fright">
                            <img src="@/assets/images/product/zhipin/shipinhuizhen.png">
                        </div>
                       
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="1500" class="clearfix" >
                        <div class="img-container">
                            <img src="@/assets/images/product/zhipin/weibiaoti.png">
                        </div>
                        <div class="text-container">
                            <h3><span>大数据分析</span></h3>
                            <p>按区域、行业、年龄段、求职意向、招聘岗位等进行数据统计</p>
                        </div>
                        
                    </li>
                </ul>
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">JOB直聘解决方案</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">用最便捷、最高效的方式实现求职者与企业的即时沟通，让招聘变得更简单！ </div>
            <div>
                <div class="wrap rly_01">
                    <ul>
                        <li class="l-body">
                            
                            <div class="sfl_02_cell sfl_02_img">
                                <img src="@/assets/images/product/zhipin/fangan1.png" alt="">
                            </div>
                            <div class="sfl_02_cell sfl_02_txt">
                                <h3 class="p1">在线全流程管理监控</h3>
                                <p class="p2">
                                    <i><span class="circle">•</span>职位发布、能岗匹配、简历管理、智能推送、面试邀请</i>
                                    <i><span class="circle">•</span>线上招聘会、录用结果、电子合同等全程线上操作</i>
                                    <i><span class="circle">•</span>招聘流程灵活性高，解决疫情无接触录用人才</i>
                                </p>
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                <div class="sfl_02_cell sfl_02_img sfl_02_img_wap">
                                    <img src="@/assets/images/product/zhipin/fangan2.png" alt="">
                                </div>
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">多渠道招聘 助力资源运营</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        职位需求/招聘广告一键发布，精准投放。</i>
                                        <i>
                                        <span class="circle">•</span>
                                        线上招聘会发布，满足企业用人需求和提升企业竞争力。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        门户/微官网，助推资源运营，提升品牌影响力。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        内外部推荐，人才挖掘，实现人才资源最大化。
                                        </i>
                                    </p>
                                    </div>
                            
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/zhipin/fangan3.png" alt="">
                                </div>
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">可视化招聘智能分析</h3>
                                    <p class="p2">
                                        <i> <span class="circle">•</span>招聘岗位多维度进行数据分析和归类</i>
                                        <i> <span class="circle">•</span>给政府和第三方机构提供有效数据支持</i>
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
                
        </div>
    </section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">JOB直聘流程管理</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">实现求职者与企业的有效沟通桥梁，让招聘更效率！ </div>
            <div class="l-body">
                <div class="mb1">
                    <img src="@/assets/images/product/zhipin/liucheng.png" />
                </div>
                
            </div>
        </div>
	</section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
//import wxapi from '../utils/wxapi'
export default {
    name:'Zhipin',
    data() {
      return {
        title:'JOB直聘',
        from:'job'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
    methods:{
        goZhipin(){
            location.href = 'https://www.jobzhipin.com/m';
        }
        
    },
     mounted() {
    },
    created(){
        //wxapi.wxRegister();
    }
}
    
</script>
<style>

</style>
