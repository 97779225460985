<template>
  <div class="educate">
    
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/educate.jpg" />
            <div class="banner-con">
                <div class="line1">教育培训</div>
                <div class="line2">学历提升、招考面试、管理提升学习及培训平台。</div>
				<!--<div class="line3"><a href="http://www.zhichangcloud.com/exam/">现在去用</a></div>-->
            </div>
        </div>
    </div>
    
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">教育培训管理解决方案</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="edu-list clearfix">

                    <div class="edu-item " data-aos="fade-left" data-aos-delay="1200">
                        <router-link :to="{path:'/xuelieducate'}" class="ico2">
                            <div class="imgdiv">
                                <img src="@/assets/images/product/educate/bg8.png" style="">
                            </div>
                            <div class="edu-title" style="">学历教育</div>
                            <div class="edu-cont" style="">设立成人教育站，针对本地企事业单位、学校老师、社会人员搭建在职学历提升的平台。</div>
                            <div class="edu-button" style="">立即报名</div>
                        </router-link>
                    </div>
                    <div class="edu-item " data-aos="fade-right" data-aos-delay="600">
                    <router-link :to="{path:'/peixundetails/6'}" class="ico2">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/bg6.png" style="">
                        </div>
                        <div class="edu-title" style="">国家机关和事业单位岗位招录人员面试培训</div>
                        <div class="edu-cont" style="">按照因材施教的原则，在充分了解学员个体特点的基础上，有针对性的制定培训课程和方案。</div>
                        <div class="edu-button" style="">立即报名</div>
                    </router-link>
                    </div>
                    <div class="edu-item " data-aos="fade-right" data-aos-delay="300">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/tzhy.png" style="">
                        </div>
                        <div class="edu-title" style="">特种行业培训</div>
                        <div class="edu-cont" style="">电工证、焊工证、高处作业证等。</div>
                        <!--<div class="edu-button" style="">立即报名</div>-->
                    </div>

                    <div class="edu-item " data-aos="fade-right" data-aos-delay="600">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/jspx.png" style="">
                        </div>
                        <div class="edu-title" style="">职业教育学校教师培训</div>
                        <div class="edu-cont" style="">中职和高职学校新教师职业素养提升培训。</div>
                        <!--<div class="edu-button" style="">立即报名</div>-->
                    </div>
                    <div class="edu-item " data-aos="fade-left" data-aos-delay="900">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/rlzygls.png" style="">
                        </div>
                        <div class="edu-title" style="">企业人力资源管理师</div>
                        <div class="edu-cont" style="">根据报名方向来制定多种学习模式及合理计划，专职教师跟踪指导。</div>
                        <!--<div class="edu-button" style="">立即报名</div>-->
                    </div>

                    

                    <div class="edu-item " data-aos="fade-right" data-aos-delay="300">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/bg5.png" style="">
                        </div>
                        <div class="edu-title" style="">创业培训</div>
                        <div class="edu-cont" style="">构建“创业教育+模拟实训+创业实践”的“三位一体”模式。使学生积累创业知识。</div>
                        <!--<div class="edu-button" style="">立即报名</div>-->
                    </div>

                    
                    <div class="edu-item " data-aos="fade-right" data-aos-delay="600">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/bg2.png" style="">
                        </div>
                        <div class="edu-title" style="">在岗普通员工职业素养培训</div>
                        <!--<div class="edu-button" style="">立即报名</div>-->
                    </div>
                    <div class="edu-item " data-aos="fade-left" data-aos-delay="1200">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/bg4.png" style="">
                        </div>
                        <div class="edu-title" style="">管理咨询专题（中高层）</div>
                        <div class="edu-cont" style="">转型期中国经济特点，行业与公司发展面临的机遇和对策分析。</div>
                    </div>
                   <div class="edu-item " data-aos="fade-right" data-aos-delay="300">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/bg1.png" style="">
                        </div>
                        <div class="edu-title" style="">新入职大学生岗前培训</div>
                        <div class="edu-cont" style="">帮助新入职大学生科学规划人生发展目标，快速转变社会角色，立足岗位。</div>
                    </div>

                    <div class="edu-item " data-aos="fade-left" data-aos-delay="900">
                        <div class="imgdiv">
                            <img src="@/assets/images/product/educate/bg3.png" style="">
                        </div>
                        <div class="edu-title" style="">基层管理队伍培训</div>
                        <div class="edu-cont" style="">激励班组长增强岗位责任心和职业发展的原动力，追求卓越业绩。</div>
                    </div>
                    
                    
                    
                </div>
            </div>
        </div>
	</section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Educate',
    data() {
      return {
        title:'教育培训',
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
