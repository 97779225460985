<template>
  <div class="educate">
    
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/educate.jpg" />
            <div class="banner-con">
                <div class="line1">教育培训</div>
                <div class="line2">学历提升、招考面试、管理提升学习及培训平台。</div>
				<!--<div class="line3"><a href="http://www.zhichangcloud.com/exam/">现在去用</a></div>-->
            </div>
        </div>
    </div>
    <section v-if="type==1">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">华东交通大学</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
               <div class="divi"><img src="@/assets/images/product/educate/huadong/bg1.png" style=""></div>
               <div class="divt">华东交通大学是所以交通为特色、 轨道为核心、多学科协调发展的教学研究型大学， 国铁集团与江西省人民政府、 国家铁路局与江西省人民政府“双共建”高校，“中西部高校基础能力建设工程”高校，
博士学位授权单位。</div>
                <div class="divt">
                学校涵盖工、经、管、文、理、法、教育、艺术等8个学科门类。拥有3个一级学科博士点、21个一级学科硕士点，工程学学科进入ESI全球前1%，3个学科入选江西省一流学科，4个学科成为江西
省学科联盟牵头学科，具17个专业学位授权类别，是硕士研究生推免工作单位。50余个专业面向全国招生，其中12个专业入选国家一流专业，15个专业入选省一流专业。拥有4个国家特色专业、3个国
家级卓越工程师试点专业，6个专业通过工程教育认证(评估)。参加全省高校首轮本科专业综合评价，16个获评第一、13个第二、6个第三。学校现有教职工2000余人，其中专任教师1200余人，正副教
授600余 人。拥有“双聘”院士、“长江学者”特聘教授、国家杰出青年基金获得者、 “万人计划”领军人才、“青年长江学者”、以及国家“百千万人才工程”、享受国务院特殊津贴、教育部“新世纪优秀
人才支持计划”、科技部“中青年科技 创新领军人才”、全国优秀教师、全国优秀教育工作者、“井冈学者” 特聘教授、“双千计划”“赣鄱英才555工程”人选等省部级以上优秀人才170余人次。
                </div>
                <div class="peixun-table">
                <div class="peixun-line line-1 ed6">
                    <div class="peixun-row row-1">培养层次</div>
                    <div class="peixun-row row-2">专业名称</div>
                    <div class="peixun-row row-1">学制</div>
                </div>
                <div class="peixun-line line-2 f9e">
                    <div class="peixun-row row-1 text-center" ><span>专升本</span></div>
                    <div class="peixun-row row-2">自动化、计算机科学与技术、交通运输、交通工程、
                        工商管理、市场营销、会计学、人力资源管理、
                        物理管理、法学</div>
                    <div class="peixun-row row-1 text-center"><span>2.5年</span></div>
                </div>
                <div class="peixun-line line-2 f9e noborder">
                    <div class="peixun-row row-1"></div>
                    <div class="peixun-row row-2 hasborder">土木工程、工程管理、音乐学</div>
                    <div class="peixun-row row-1 hasborder">3年</div>
                </div>
            </div>
            <div class="h20"></div>
            <div class="peixun-table">
                <div class="peixun-line line-1 bc235">
                    <div class="peixun-row row-1">培养层次</div>
                    <div class="peixun-row row-2">专业名称</div>
                    <div class="peixun-row row-1">学制</div>
                </div>
                <div class="peixun-line line-2 bcd71">
                    <div class="peixun-row row-1 text-center"><span>高升本</span></div>
                    <div class="peixun-row row-2">国家经济与贸易、电子信息工程、体育教育、
                        数字媒体艺术、运动训练、汉语言文学、英语、
                        软件工程、电气工程及其自动化、计算机科学与技术、
                        土木工程、交通运输、交通工程、工程管理、
                        人力资源管理、金融学、工商管理、市场营销、
                        会计学、音乐学、舞蹈表演</div>
                    <div class="peixun-row row-1 text-center"><span>5年</span></div>
                </div>
            </div>
            </div>
        </div>
	</section>

     <section v-if="type==2">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">九江职业技术学院</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
               <div class="divi"><img src="@/assets/images/product/educate/zhida/bg1.png" style=""></div>
               <div class="divt">九江职业技术学院是经教育部批准成立的全日制普通高等学校，创办于1960年，前身为九江船舶工业学校，先后隶属第一、
第三、第六机械工业部、中国船舶工业总公司。1994年，成为全国首批举办高等职业教育的10所试点学校之一。1999年，学校
由国防科工委划转至江西省人民政府管理，并升格更名为九江职业技术学院，是江西省教育厅直属高职院校。2010年，通过教育
部、财政部“国家示范性高职院校”建设项目验收，成为江西省唯一一所国家示范性高等职业院校。2014年，成为江西省首批联
合培养应用技术型本科人才试点院校。2018年，被省教育厅、财政厅遴选为江西省“高水平高等职业院校和优势特色专业”立项
建设单位。2019年，被教育部确定为“国家优质专科高等职业院校”，成功被教育部、财政部遴选为“中国特色高水平高职学校
和专业建设计划”立项建设单位，成为江西省唯一一所“中国特色高水平高职学校”立项建设单位。</div>
                <div class="h20"></div>
            <div class="peixun-table">
                <div class="peixun-line line-1 bc235">
                    <div class="peixun-row row-1">培养层次</div>
                    <div class="peixun-row row-2">专业名称</div>
                    <div class="peixun-row row-1">学制</div>
                </div>
                <div class="peixun-line line-2 bcd71">
                    <div class="peixun-row row-1 text-center"><span class="">高起专</span></div>
                    <div class="peixun-row row-2">
                        <span style="display: inline-block;width: 100%">文理兼收</span>建筑工程技术、供热通风与空调工程技术、模具设计与制造、数控设备运用与维护、机电一体化技术、电气自动化技术、船舶工程技术、汽车检测与维修技术、应用电子技术、计算机网络技术、软件技术、通信技术、建设工程管理、连锁经营管理、游戏设计、商务英语、工程造价、会计、工商企业管理、物流管理、人力资源管理</div>
                    <div class="peixun-row row-1 text-center"><span>2.5年</span></div>
                </div>
                <div class="peixun-line line-2 bcd71 noborder">
                    <div class="peixun-row row-1"></div>
                    <div class="peixun-row row-2 hasborder">
                        <span style="display: inline-block;width: 100%">理工类</span>机械设计与制造、数控技术、汽车电子技术、船舶检验电子信息工程技术、移动互联应用技术、物联网应用技术</div>
                    <div class="peixun-row row-1"></div>
                </div>
            </div>
            </div>
        </div>
	</section>
     <section v-if="type==3">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">江西师范大学</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
               <div class="divi"><img src="@/assets/images/product/educate/shida/bg1.png" style=""></div>
               <div class="divt">江西师范大学是教育部、江西省人民政府共建高校和中西部高校基础能力建设工程高校。学校融哲学、经济学、法学、教育学、
文学、历史学、理学、工学、管理学、艺术学等十大学科门类于一体，是教育部、江西省人民政府共建高校和中西部高校基础能力建
设高校，是江西省人民政府确定为优先发展的省属重点(师范)大学。学校现有瑶湖、青山湖两个校区，占地面积3500余亩，建筑面积
140余万平方米。</div>
               <div class="h20"></div>
                <div class="peixun-table">
                    <div class="peixun-line line-1 bc2fa">
                        <div class="peixun-row row-1">培养层次</div>
                        <div class="peixun-row row-2">专业名称</div>
                        <div class="peixun-row row-1">学制</div>
                    </div>
                    <div class="peixun-line line-2 bcd9e">
                        <div class="peixun-row row-1 text-center"><span class="">专科专业</span></div>
                        <div class="peixun-row row-2">
                            建筑工程技术，机电一体化技术，计算机信息管理，
                            工商企业管理，学前教育，行政管理，会计，药学，
                            应用化工技术
                        </div>
                        <div class="peixun-row row-1 text-center"><span>2.5年</span></div>
                    </div>
                    <div class="peixun-line line-2 bcd9e noborder">
                        <div class="peixun-row row-1 hasborder text-center"><span>本科专业</span></div>
                        <div class="peixun-row row-2 hasborder">
                            土木工程，行政管理，工商管理，机械设计制造及其
                            自动化，学前教育、计算机科学与技术
                        </div>
                        <div class="peixun-row row-1"></div>
                    </div>
                </div>
            </div>
        </div>
	</section>

    <XueliForm :type="type" :laiyuan="laiyuan" />


    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
import XueliForm from '../components/XueliForm'
export default {
    name:'XueliBaoming',
    data() {
      return {
        title:'学历报名',
        type:null,
        laiyuan:1
      }
    },
    computed: {
     
    },
    watch:{
    },
    components: {
        Bottom,
        Menu,
        XueliForm
    },
    directives: {

    },
     mounted() {
         this.type=this.$route.params.type;
         console.log(this.type);
    }
}
    
</script>
<style scoped>
    .divi{
        padding:.5rem 0
    }
    .divt{
        text-indent:2rem;
        text-align:left
    }
    .peixun-table{
    width: 100%;
    margin: 0 auto;
    font-size: 1rem;
}
.peixun-table .peixun-line{
    display: flex;
    min-height: 40px;

}
.bc235{
    background-color: #235ba0;
}
.bcd71{
    background-color: #d7e0ed;
}
.ed6{
    background-color: #ed6b1a;
}
.f9e{
    background-color: #f9e9d2;
}
.bc2fa{
    background-color: #2fae85;
}
.bc2fa{
    background-color: #2fae85;
}
.bcd9e{
    background-color: #d9ede5;
}
.peixun-table .peixun-line.line-1{

    color: #ffffff;
    height: 40px;
    line-height: 40px;
}
.peixun-table .peixun-line.line-2{

    border-top:3px solid #ffffff;
    line-height: 2;
    color: #000000;
}
.peixun-table .peixun-line.line-2.noborder{

    border-top:none
}
.peixun-table .peixun-line.line-2.noborder .peixun-row.hasborder{

    border-top:3px solid #ffffff;
}
.peixun-table .peixun-line .peixun-row{
    border-right:4px solid #ffffff;
    box-sizing: border-box;
}
.peixun-table .peixun-line .peixun-row:last-child{
    border-right:0;
}
.peixun-table .peixun-line .row-1{
    width: 20%;
}
.peixun-table .peixun-line .row-2{
    flex:1;
}
.h20{
    height: 20px;
}
.text-center{
    justify-content: center;
    align-items: center;
    display: flex;
}
</style>
