<template>
  <div class="product-banner" style="">
    <div class="banner-i clearfix"><img src="@/assets/images/product/zhipin/top-bg.jpg" style=""></div>
    
    <div v-if='bh' class="banner-cont" style="">
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductBanner',
  props: {
    bhref:String
  },
  data(){
    return {
      bh:null,
      
    }
  },
  computed:{
    
  },
  mounted(){
   
    this.bh=this.bhref?this.bhref:null;
  },
}
</script>
<style scoped>
</style>
