<template>
  <div class="econtract">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/apply.jpg" />
        </div>
    </div>
     <Form :type="this.$route.params.type" :from="from" />
    
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
import Form from '../components/Form'
export default {
    name:'Apply',
    data() {
      return {
        title:'预约产品演示',
        type:null,
        from:'apply'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu,
        Form
    },
    directives: {

    },
     mounted() {
       this.type=this.$route.params.type;
    }
}
    
</script>
<style>

</style>
