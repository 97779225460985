<template>
  <div class="zhipin labor">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/jiameng.jpg" />
            <div class="banner-con" style="left:0;width: 100%;text-align: center;">
            <div class="line1">诚邀优秀合作伙伴</div>
            <div class="line1">共拓Saas新市场</div>
            <div class="line3" style="margin-top:15px;"><a href="#form">立即申请</a></div>
          </div>
        </div>
    </div>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">全面为您的业务开发保驾护航</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">平台助力，快速起步</div>
            <div class="l-body">
                <ul class="clearfix hxld">
                    <li data-aos="fade-left" data-aos-delay="300" class="clearfix" >
                        <div class="img-container">
                            <img src="@/assets/images/product/jiameng/xiaoshourenyuan.png">
                        </div>
                        <div class="text-container">
                            <h3><span>市场支持</span></h3>
                            <p>区域经理线上远程，线下陪访，提升转化效率,线上曝光、线下推广，统一品牌形象,快速占据市场</p>
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="600" class="clearfix" >
                        
                        <div class="text-container fleft">
                            <h3><span>培训支持</span></h3>
                            <p>直播培训，视频教学，以战代练，助力快速熟悉业务</p>
                        </div>
                        <div class="img-container fright">
                            <img src="@/assets/images/product/jiameng/zcpt-peixundianboguanli.png">
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="900"  class="clearfix">
                        <div class="img-container">
                            <img src="@/assets/images/product/jiameng/fenxiaoshichang-mianxing.png">
                        </div>
                        <div class="text-container">
                            <h3><span>技术支持</span></h3>
                            <p>平台拥有强大的技术团队作为支撑,即时处理客户使用平台的技术问题,快捷部署系统、24小时在线服务</p>
                        </div>
                        
                    </li>
                </ul>
            </div>
        </div>
	</section>

    <section style="background-color: #f5f9f9;">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">我们希望志同道合的小伙伴是这样的</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="jfa_01_c">
                    <ul>
                        <li  data-aos="fade-right" data-aos-delay="300" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/jiameng/zizhi1.png" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">具备公司资质</i>
                                    <i>有合法营业执照和独立法人代表，有良好商业信誉和口碑。</i>
                                </em>
                            </div>
                        </li>
                        <li  data-aos="fade-right" data-aos-delay="600" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/jiameng/zizhi2.png" class="" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">认可职畅云文化</i>
                                    <i>认同职畅云产品价值和文化理念，愿意跟职畅云长期合作。</i>
                                </em>
                            </div>
                        </li>
                        <li  data-aos="fade-left" data-aos-delay="900" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/jiameng/zizhi3.png" class="" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">具有行业背景和客户资源</i>
                                    <i>有互联网背景，对Saas行业有了解，拥有丰富的当地企业客户资源。</i>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1200" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/jiameng/zizhi4.png" style="">
                            </div>

                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">具有销售团队</i>
                                    <i>有专属且成熟的销售团队，负责人有团队管理经验。</i>
                                </em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>
    
     <Form :type="type" :from="from" />
    
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
import Form from '../components/Form'
export default {
    name:'Apply',
    data() {
      return {
        title:'合作加盟',
        type:"0",
        from:'jiameng'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu,
        Form
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
