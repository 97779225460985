import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import filters from './utils/filters'
import '@/assets/css/common.css'
import '@/assets/css/style.css'

//import wxShare from './utils/wxShare'
//Vue.prototype.$wxShare = wxShare

Vue.config.productionTip = false
Vue.use(ElementUI)
Object.keys(filters).forEach((v)=>{
  Vue.filter(v,filters[v]);
})
router.beforeEach((to,form,next)=>{
  if(to.meta.title){
    document.title=to.meta.title;
  }
  next();
})
new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
