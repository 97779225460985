<template>
  <div class="econtract">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/econtract.jpg" />
            <div class="banner-con">
                <div class="line1">电子合同</div>
                <div class="line2">合同线上签署、存贮查询方便、司法保障。</div>
                <div class="line3"><a href="https://api.gwyapp.net/Content/one_key_recruitment/ab/index.html#/contractJbLogin?t=1">现在去用</a></div>
            </div>
        </div>
    </div>
    <section>
        <div class="act_x vs">
            <div class="act_x_t size-40 color-333 l-title">纸质合同的"坑"，电子合同来解决</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="item item3-1">
                    <div class="title">
                        <div class="img img1 data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="200">
                            <img  src="@/assets/images/product/hetong/vs-bgleft.png" />
                        <span>纸质合同</span>
                        </div>
                        <p class="vs data-aos-init data-aos-animate" data-aos="fade-down" data-aos-delay="400">VS</p>
                        <div class="img img2 data-aos-init data-aos-animate" data-aos="fade-left" data-aos-delay="600">
                        <img  src="@/assets/images/product/hetong/vs-bgright.png" />
                        <span>电子合同</span>
                        </div>
                    </div>
                    <div class="itemBody">
                        <div class="box box-left data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="800">
                            <ul>
                                <li>
                                    <p class="list-title">签约效率低</p>
                                    <p>打印、审批、邮寄平均耗时3-5个工作日</p>
                                </li>
                                <li>
                                    <p class="list-title">人工成本高</p>
                                    <p>合同管理专人跟进，印章专人保管</p>
                                </li>
                                <li>
                                    <p class="list-title">经济成本高</p>
                                    <p>设备耗材、纸张、快递费等，20-50元</p>
                                </li>
                                <li>
                                    <p class="list-title">机会成本高</p>
                                    <p>时间越长，越可能错过商业合作机会</p>
                                </li>
                                <li>
                                    <p class="list-title">存在一定风险</p>
                                    <p>存在非本人签、篡改合同的法律风险</p>
                                </li>
                            </ul>
                        </div>
                        <div class="box-center data-aos-init data-aos-animate" data-aos="fade-down" data-aos-delay="1000">
                            <div>
                                <img src="@/assets/images/product/hetong/vs-icon1.png">
                                <img src="@/assets/images/product/hetong/vs-icon2.png">
                                <img src="@/assets/images/product/hetong/vs-icon3.png">
                                <img src="@/assets/images/product/hetong/vs-icon4.png">
                                <img src="@/assets/images/product/hetong/vs-icon5.png">
                            </div>
                        </div>
                        <div class="box box-right data-aos-init data-aos-animate" data-aos="fade-left" data-aos-delay="1200">
                            <ul>
                                <li>
                                    <p class="list-title">线上签约便捷高效</p>
                                    <p>全程线上签约，平均耗时10-15分钟</p>
                                </li>
                                <li>
                                    <p class="list-title">人工成本低</p>
                                    <p>全流程自动提醒，自助签章</p>
                                </li>
                                <li>
                                    <p class="list-title">经济成本大大降低</p>
                                    <p>电子签署+公证服务+司法鉴定，5-10元</p>
                                </li>
                                <li>
                                    <p class="list-title">机会不再错过</p>
                                    <p>当天确认，双方签章，不错过任何商机</p>
                                </li>
                                <li>
                                    <p class="list-title">签约安全有保障</p>
                                    <p>实名认证、CA认证、防篡改、一键验真</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">核心功能</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="p14_01_c">
                    <ul>
                        <li data-aos="fade-right" data-aos-delay="300" >
                            <img src="@/assets/images/product/hetong/qianming-icon.png?v=1" class="velocity-animating" style="transform: rotateZ(2.00107deg);">
                            <div class="p8_02_c jfa_01c_c">
                                <em class="em1">
                                    <h3 class="sp1">电子签名</h3>
                                    <span class="sp2">全程电子化签署，文件在线流转，极大提高文书签署效率</span>

                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-right" data-aos-delay="600" >
                            <img src="@/assets/images/product/hetong/guanli-icon.png?v=1" class="" style="transform: rotateZ(0deg);">
                            <div class="p8_02_c jfa_01c_c">
                                <em class="em1">
                                    <h3 class="sp1">合同管理</h3>
                                    <span class="sp2">合同文件电子化存储，标签分类提升管理效率，一键搜索所见即所得</span>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="900"  >
                            <img src="@/assets/images/product/hetong/cunzheng-icon.png?v=1">
                            <div class="p8_02_c jfa_01c_c" style="margin-bottom: 3rem;">
                                <em class="em1">
                                    <h3 class="sp1">存证保全</h3>
                                    <span class="sp2">电子签名全过程数据存证，证据链条完整可靠，联合蚂蚁区块链，打造行业首个区块链电子合同存证平台</span>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1200"   >
                            <img src="@/assets/images/product/hetong/falv-icon.png?v=1">
                            <div class="p8_02_c jfa_01c_c" style="margin-bottom: 3rem;">
                                <em class="em1">
                                    <h3 class="sp1">法律服务</h3>
                                    <span class="sp2">联合公证处，司法鉴定中心，仲裁委，整合遍布全国的线下律师服务，打造安全无忧的司法保障服务体系</span>
                                </em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>
<!--分隔符-->
     <section>
        <div class="act_x">
            <div>
                <div class="wrap rly_01">
                    <ul>
                        <li class="l-body">
                            
                            
                            <div class="sfl_02_cell sfl_02_txt">
                                <h3 class="p1">云数据安全</h3>
                                <p class="p2">
                                    <i>
                                    <span class="circle">•</span>
                                    ISO27001国际权威的信息安全管理体系认证
                                    </i>
                                    <i>
                                    <span class="circle">•</span>
                                    ISO 27018公有云个人信息隐私保护国际认证
                                    </i>
                                    <i>
                                    <span class="circle">•</span>
                                    公安部信息系统安全等级保护三级
                                    </i>
                                </p>
                            </div>
                            <div class="sfl_02_cell sfl_02_img">
                                <img src="@/assets/images/product/hetong/gst_01.png" alt="">
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                               
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">签约速度快</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        3分钟线上签发合同操作便捷
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        高效协同
                                        </i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img sfl_02_img_wap">
                                    <img src="@/assets/images/product/hetong/gst_02.png" alt="">
                                </div>
                            
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">安全系数高</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        合同管理全流程电子化区块链证据保全
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        碎片化加密存储
                                        </i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/hetong/gst_03.png" alt="">
                                </div>
                            </div>
                        </li>
                        <li class="l-body">
                            <div class="wrap_in">
                                
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">使用成本低</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        无需快递
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        不占仓储节省95%的运营成本
                                        </i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/hetong/gst_04.png" alt="">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!--分隔符-->
    <section>
        <div class="act_x flxl">
            <div class="act_x_t size-40 color-333 l-title">法律效力</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="cont">
                    <ul>
                        <li data-aos="fade-right" data-aos-delay="300" >
                            <div class="item">
                                <div class="tit">
                                    <p class="p1">《中华人民共和国电子签名法》</p>
                                    <p class="p2">明确了电子签名的合法性及有效性。</p>
                                </div>
                                <dl>
                                    <dt>第十三条</dt>
                                    <dd>电子签名同时符合下列条件的,视为可靠的电子签名:</dd>
                                    <dd>(一)电子签名制作数据用于电子签名时,属于电子签名人专有;</dd>
                                    <dd>(二)签署时电子签名制作数据仅由电子签名人控制;</dd>
                                    <dd>(三)签署后对电子签名的任何改动能够被发现;</dd>
                                    <dd>(四)签署后对数据电文内容和形式的任何改动能够被发现当事人也可以选择使用符合其约定的可靠条件的电子签名。</dd>
                                    <dt>第十四条</dt>
                                    <dd>可靠的电子签名与手写签名或者盖章具有同等的法律效力。</dd>
                                </dl>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="600" >
                            <div class="item">
                                <div class="tit">
                                    <p class="p1">《中华人民共和国民法典》</p>
                                    <p class="p2">认可以数据电文作为合同书面形式的合法载体。</p>
                                </div>
                                <dl>
                                    <dt>第四百六十九条</dt>
                                    <dd>当事人订立合同，可以采用书面形式、口头形式或者其他形式。</dd>
                                    <dd>书面形式是合同书、信件、电报、电传、传真等可以有形地表现所载内容的形式。</dd>
                                    <dd>以电子数据交换、电子邮件等方式能够有形地表现所载内容，并可以随时调取查用的数据电文，视为书面形式。</dd>
                                </dl>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="900" >
                            <div class="item">
                                <div class="tit">
                                    <p class="p1">《中华人民共和国民事诉讼法》</p>
                                    <p class="p2">明确将电子数据纳入民事诉讼证据，详细界定了作为民事诉讼证据类型的电子数据。</p>
                                </div>
                                <dl>
                                    <dt>第六十三条</dt>
                                    <dd>证据包括：</dd>
                                    <dd>（一）当事人的陈述；</dd>
                                    <dd>（二）书证；</dd>
                                    <dd>（三）物证；</dd>
                                    <dd>（四）视听资料；</dd>
                                    <dd>（五）电子数据；</dd>
                                    <dd>（六）证人证言；</dd>
                                    <dd>（七）鉴定意见；</dd>
                                    <dd>（八）勘验笔录。</dd>
                                </dl>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">电子合同流程管理</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div> <img src="@/assets/images/product/hetong/liucheng.png" /></div>
            </div>
        </div>
	</section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Econtract',
    data() {
      return {
        title:'电子合同',
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
