<template>
  <div class="labor">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/labor.jpg" />
            <div class="banner-con">
                <div class="line1">用工宝</div>
                <div class="line2">解决企业用工安全风险，降低企业用工成本。</div>
                <div class="line3"><a href="http://www.bznins.com/index">现在去用</a></div>
            </div>
        </div>
    </div>

    <!--<section class="solution">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">您是否有这些问题？</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body sol-con">
                <div class="sol-con-inner">
                    <div class="chain-info " data-aos="fade-right">
                        <div class="question">问题1</div>
                        <div class="info-inner">
                            <p>企业新招员工试用期期间流失较大，企业购买社会保险风险投入成本太高？</p>
                        </div>
                    </div>

                    <div class="chain-info " data-aos="fade-left">
                        <div class="question">问题2</div>
                        <div class="info-inner">
                            <p>国家政策进一步明确“五险合一”，不能单独参加工伤及医疗险种；</p>
                        </div>
                    </div>

                    <div class="chain-info " data-aos="fade-right">
                        <div class="question">问题3</div>
                        <div class="info-inner">
                            <p>退休人员需要返聘，年龄已超过社会保险的限制，如何保证安全工作？</p>
                        </div>
                    </div>

                    <div class="chain-info " data-aos="fade-left" style="margin-bottom: 0">
                        <div class="question">问题4</div>
                        <div class="info-inner">
                            <p>学校即将别有的学生参加毕业实习，潜在风险比在校提升很多，如何降低风险，解决学校的后顾之忧？</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>-->
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">您是否有这些问题？</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="yunying-left clearfix">
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="500">
                        <div class="img-container" style="">
                            <img src="@/assets/images/product/funeng/wen-icon.png">
                        </div>
                        <div class="text-container" style="">
                            
                            <div class="t2" style="">企业新招员工试用期期间流失较大，企业购买社会保险风险投入成本太高？
                            </div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="1000">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/wen-icon.png">
                        </div>
                        <div class="text-container">
                           
                            <div class="t2">国家政策进一步明确“五险合一”，不能单独参加工伤及医疗险种；</div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-right" data-aos-delay="1500">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/wen-icon.png">
                        </div>
                        <div class="text-container">
                           
                            <div class="t2">退休人员需要返聘，年龄已超过社会保险的限制，如何保证安全工作？</div>
                        </div>
                    </div>
                    <div class="yunying-item data-aos-init data-aos-animate" data-aos="fade-left" data-aos-delay="2000">
                        <div class="img-container">
                            <img src="@/assets/images/product/funeng/wen-icon.png">
                        </div>
                        <div class="text-container">
                            
                            <div class="t2">学校即将别有的学生参加毕业实习，潜在风险比在校提升很多，如何降低风险，解决学校的后顾之忧？</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
	</section>

    <section class="bg-f8">
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">产品优势</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="p1">
                    <img src="@/assets/images/product/labor/cando-bg.png" />
                </div>
                
            </div>
        </div>
	</section>

    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">我们的亮点</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="jfa_01_c">
                    <ul>
                        <li  data-aos="fade-right" data-aos-delay="300" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/labor/shouhouwuyou.png?v=2" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">企业经营无忧</i>
                                    <i>安心面对员工工伤 和意外风险 。</i>
                                </em>
                            </div>
                        </li>
                        <li  data-aos="fade-right" data-aos-delay="600" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/labor/anquan.png?v=2" class="" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">风险全面保障</i>
                                    <i>员工因工或其他意外全面保障。</i>
                                </em>
                            </div>
                        </li>
                        <li  data-aos="fade-left" data-aos-delay="900" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/labor/baohu.png?v=2" class="" style="">
                            </div>
                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">保障雇主利益</i>
                                    <i>赔付款直接支付给企业。</i>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1200" >
                            <div class="img-contain">
                                <img src="@/assets/images/product/labor/yangshi.png?v=2" style="">
                            </div>

                            <div class="jfa_01c_c">
                                <em class="em1">
                                    <i class="ti">赔付流程简洁</i>
                                    <i>一个电话，剩下的事情我们来处理。</i>
                                </em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>

    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
export default {
    name:'Labor',
    data() {
      return {
        title:'用工宝',
        bhref:'#'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
