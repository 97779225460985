<template>
  <div class="econtract">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/product.jpg" />
        </div>
    </div>
    <section>
        <div class="index_market_box">
            <div class="size-40 color-333 text-center">互联网+人力资源服务平台</div>
            <div class="title_down_line"></div>
            <div class="index_market">
                <swiper>
                <swiper-slide>
                    <div class="index_market_item">
                    <router-link :to="{path:'/zhipin'}">
                        <img class="index_market_img" src="@/assets/images/nav-header/zhipin.png" alt="">
                        <h2 class="size-14 color-333">JOB直聘</h2>
                        <div class="size-12 desc">便捷、高效，使求职、应聘变得更简单！ </div>
                    </router-link> 
                    
                    </div>
                    <div class="index_market_item">
                    <router-link :to="{path:'/serve'}">
                        <img class="index_market_img" src="@/assets/images/nav-header/serve.png" alt="">
                        <h2 class="size-14 color-333">人力资源服务系统</h2>
                        <div class="size-12 desc">客户、人资公司、员工个人一体化信息共享平台。</div>
                    </router-link> 
                    </div>
                    <div class="index_market_item">
                    <router-link :to="{path:'/econtract'}"> 
                        <img class="index_market_img" src="@/assets/images/nav-header/hetong.png" alt="">
                        <h2 class="size-14 color-333">电子合同</h2>
                        <div class="size-12 desc" >合同线上签署、存贮查询方便、司法保障。</div>
                    </router-link> 
                    </div>
                    <div class="index_market_item">
                    <router-link :to="{path:'/flexible'}">  
                    <img class="index_market_img" src="@/assets/images/nav-header/flexible.png" alt="">
                    <h2 class="size-14 color-333">灵活用工</h2>
                    <div class="size-12 desc">灵活用工个人平台、灵活就业人员接单，业务交易、结算平台。</div>
                    </router-link>
                    </div>
                    <div class="index_market_item">
                    <router-link :to="{path:'/manager'}"> 
                        <img class="index_market_img" src="@/assets/images/nav-header/manage.png" alt="">
                        <h2 class="size-14 color-333">人力资源管理系统</h2>
                        <div class="size-12 desc">区（县）级人社部门的大数据平台。 </div>
                    </router-link>
                    </div>
                    
                    
                    <div class="index_market_item">
                    <router-link :to="{path:'/labor'}">
                        <img class="index_market_img" src="@/assets/images/nav-header/ygbao.png" alt="">
                        <h2 class="size-14 color-333">用工宝</h2>
                        <div class="size-12 desc">解决企业用工安全风险，降低企业 用工成本。</div>
                    </router-link> 
                    </div>
                    <div class="index_market_item"> 
                    <router-link :to="{path:'/empowerment'}">
                        <img class="index_market_img" src="@/assets/images/nav-header/funeng.png" alt="">
                        <h2 class="size-14 color-333">产业园赋能</h2>
                        <div class="size-12 desc">平台赋能+政策赋能。 </div>
                    </router-link> 
                    </div>
                    <div class="index_market_item">
                    <router-link :to="{path:'/educate'}"> 
                        <img class="index_market_img" src="@/assets/images/nav-header/hetong.png" alt="">
                        <h2 class="size-14 color-333">教育培训</h2>
                        <div class="size-12 desc">学历提升、招考面试、管理提升学习及培训平台。</div>
                    </router-link>  
                    </div>
                    <div class="index_market_item" style="float:none;">
                    <router-link :to="{path:'/danbao'}">  
                        <img class="index_market_img" src="@/assets/images/nav-header/yundanbao.png" alt="">
                        <h2 class="size-14 color-333">云担保</h2>
                        <div class="size-12 desc">蓝领人力资源行业撮合担保交易系统。</div>
                    </router-link> 
                    </div>
                </swiper-slide>
                </swiper>
            </div>
        </div>
    </section>
    
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import Menu from '../components/Menu'
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
export default {
    name:'Product',
    data() {
      return {
        title:'产品服务'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        Menu,
        Swiper,
        SwiperSlide,
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
