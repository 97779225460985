<template>
    <div class="form-wrapper" id="form" >
    
      <div class="inner" >
      <div class="tips-con" style="padding:10px 0;">
            <div class="tips" style="text-align:center;color:red;">
            请加微信或打电话13767270213（微信同号）
            </div>
            <div class="tips" style="text-align:center;color:red;">
            了解报名详情
            </div>
      </div>
      
          <form method="post" action="">
            <div class="form-item">
                <input type="text" placeholder="联系人姓名" autocomplete="off" v-model="form_data.linkname" name="linkname">
            </div>
            <div class="form-item">
                <input type="text" placeholder="联系人手机号" autocomplete="off" v-model="form_data.linkphone" name="linkphone">

            </div>
            <div class="form-item yzm-wrap">
                <input type="text" name="captcha" autocomplete="off"  placeholder="验证码" v-model="captcha">
                <div class="yzm" @click="refreshCode">
                    <Identify :identifyCode="identifyCode" ></Identify>
                </div>
            </div>

            <div class="submit-wrap">
              <button type="button" @click="submitForm">提交</button>
            </div>
        </form>

      </div>
  </div>
</template>

<script>
import Identify from '../components/Identify';
import axios from 'axios'
import {SERVE_URL,PROXY_URL} from '../constant.js'
export default {
  name: 'XueliForm',
  data(){
    return {
        identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz",
        identifyCode: "",
        form_data:{
            laiyuan:null,
            type:null,
            linkname:'',
            linkphone:'',
        },
        captcha:'',
        btnStatus:true
    }
  },
  props:["laiyuan","type"],
  watch:{
      type(newValue,oldValue){
        this.form_data.type=newValue;
        console.log(this.form_data.type);
      },
      laiyuan(newValue,oldValue){
        this.form_data.laiyuan=newValue;
      },

  },
  mounted(){
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    localStorage.setItem("code", this.identifyCode);
    this.form_data.laiyuan=this.laiyuan;
    
  },
  
  methods:{
      //刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      localStorage.setItem("code", this.identifyCode);
    },
      //生成验证上的随机数，验证码中的数从identifyCodes中取，
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    //生成随机数,这里是生成
    //Math.random()方法返回大于等于0小于1的一个随机数
    //随机数 = Math.floor(Math.random() * 可能的总数 + 第一个可能的值)
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    submitForm(){
        if(!this.btnStatus){
            return false;
        }
        let code=localStorage.getItem("code");
        if(code!=this.captcha || (this.captcha=='')){
            this.$message.error('验证码错误');
            return false;
        }
        if( (this.form_data.linkname=='') || (this.form_data.linkname==null)){
            this.$message.error('联系人姓名不能为空');
            return false;
        }
        
        let phoneReg=/^1[2|3|4|5|6|7|8|9]\d{9}$/;
        if(!phoneReg.test(this.form_data.linkphone)){
            this.$message.error('手机号不正确');
            return false;
        }
        let _this=this;
        this.btnStatus=false;
        axios({
            method: 'post',
            url: PROXY_URL+'/Api/Index/xueliForm',
            data: this.form_data
        }).then(function (response) {
            if(response.data.code==1){
                _this.$message.success(response.data.msg);
                _this.form_data.linkname='';
                _this.form_data.linkphone='';
            }else{
                _this.$message.error(response.data.msg);
            }
            _this.refreshCode();
            _this.btnStatus=true;
        })
        .catch(function () {
            _this.btnStatus=true;
            _this.$message.error('请求服务器失败');
        });


    }
  },
  components: {
    Identify
  },
  computed:{
  }
}
</script>
<style scoped>

.form-wrapper .inner {
    background: #fff;
    border-radius: 4px;
    padding: 0 2rem 1rem;
}
.form-wrapper .inner h3 {
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}
.form-wrapper .inner .form-item {
    width: 100%;
    padding-bottom: 1.8rem;
    position: relative;
}
.form-wrapper .inner .form-item input, .form-wrapper .inner .form-item select, .form-wrapper .inner .form-item textarea {
    height: 2.5rem;
    line-height: 2.5rem;
    width: 100%;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #C3C3C3;
    border-radius: 5px;
    box-shadow: none;
    font-weight: 400;
    font-style: normal;
    font-size: .85rem;
    color: #c3c3c3;
    text-align: left;
    padding-left: .85rem;
}
.form-wrapper .inner .yzm-wrap {
    position: relative;

}
.form-wrapper .inner .yzm-wrap .yzm {
    position: absolute;
    top: 1px;
    bottom:1px;
    right: 0;
    width: 119px;
    
}
.form-wrapper .inner .yzm-wrap .yzm  #s-canvas{
border-left: 1px solid #c3c3c3;
}
.form-wrapper .inner .yzm-wrap img {
    height: 100%;
    width: 100%;
    background-color: rgba(34, 169, 173, 1);
    border: none;
    border-radius: 5px;
    line-height: 5.7rem;
    color: #fff;
    cursor: pointer;
}
.form-wrapper .inner .submit-wrap {
    width: 100%;
}
.form-wrapper .inner .submit-wrap button {
    height: 2.5rem;
    width: 100%;
    background-color: #20ad91;
    border: none;
    border-radius: 5px;
    line-height: 2.5rem;
    font-size: .85rem;
    color: #fff;
    cursor: pointer;
    outline: none;
}
</style>
