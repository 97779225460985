import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/Index'
import Zhipin from '../pages/Zhipin'
import Product from '../pages/Product'
import Serve from '../pages/Serve'
import Manager from '../pages/Manager'
import Econtract from '../pages/Econtract'
import EcontractW from '../pages/EcontractW'
import Flexible from '../pages/Flexible'
import Labor from '../pages/Labor'
import Empowerment from '../pages/Empowerment'
import Educate from '../pages/Educate'
import EducateE from '../pages/XueliE'
import XueliBaoming from '../pages/XueliBaoming'  //学历报名
import PeixunDetails from '../pages/PeixunDetails'  //学历报名

import Danbao from '../pages/Danbao'
import Otherserve from '../pages/Otherserve'
import Newslist from '../pages/Newslist'
import Newsdetail from '../pages/Newsdetail'
import Partner from '../pages/Partner'
import About from '../pages/About'
import Demon from '../pages/Demon'
import Apply from '../pages/Apply'
import Jiameng from '../pages/Jiameng'
Vue.use(VueRouter)
export default new VueRouter({
	mode: 'history',
    routes:[
        {
            path:'/',
            component:Index,
            meta:{
                title:'职畅云'
            }
        }
        ,{
            path:'/zhipin',
            component:Zhipin,
            meta:{
                title:'JOB直聘'
            }
        }
        ,{
            path:'/product',
            component:Product,
            meta:{
                title:'产品服务'
            }
        }
        ,{
            path:'/serve',
            component:Serve,
            meta:{
                title:'人力资源服务系统'
            }
        }
        ,{
            path:'/manager',
            component:Manager,
            meta:{
                title:'人力资源管理系统'
            }
        }
        ,{
            path:'/econtract',
            component:Econtract,
            meta:{
                title:'电子合同'
            }
        },{
            path:'/econtractw',
            component:EcontractW,
            meta:{
                title:'电子合同'
            }
        }
        ,{
            path:'/flexible',
            component:Flexible,
            meta:{
                title:'灵活用工'
            }
        }
        ,{
            path:'/labor',
            component:Labor,
            meta:{
                title:'用工宝'
            }
        }
        ,{
            path:'/empowerment',
            component:Empowerment,
            meta:{
                title:'产业园赋能'
            }
        }
        ,{
            path:'/educate',
            component:Educate,
            meta:{
                title:'教育培训'
            }
        }
       
        ,{
            path:'/xuelieducate',
            component:EducateE,
            meta:{
                title:'学历教育'
            }
        }
        ,{
            path:'/xuelibaoming/:type',
            component:XueliBaoming,
            meta:{
                title:'学历报名'
            }
        }
        ,{
            path:'/peixundetails/:laiyuan',
            component:PeixunDetails,
            meta:{
                title:'培训报名'
            }
        }
        ,{
            path:'/danbao',
            component:Danbao,
            meta:{
                title:'云担保'
            }
        }
        ,{
            path:'/otherserve',
            component:Otherserve,
            meta:{
                title:'其他服务'
            }
        }
        ,{
            path:'/newslist',
            component:Newslist,
            meta:{
                title:'行业资讯'
            }
        }
        ,{
            name:'newsdetail',
            path:'/newsdetail/:id',
            component:Newsdetail,
            meta:{
                title:'资讯详情'
            }
        },{
            path:'/partner',
            component:Partner,
            meta:{
                title:'合作伙伴'
            }
        }
        ,{
            path:'/about',
            component:About,
            meta:{
                title:'联系我们'
            }
        }
        ,{
            path:'/demon',
            component:Demon,
            meta:{
                title:'在线演示'
            }
        }
        ,{
            path:'/apply/:type',
            component:Apply,
            meta:{
                title:'预约演示'
            }
        }
        ,{
            path:'/jiameng',
            component:Jiameng,
            meta:{
                title:'合作加盟'
            }
        }
    ]
})