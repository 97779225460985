<template>
  <div class="econtract">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/danbao.jpg" />
            <div class="banner-con">
                <div class="line1">云担保</div>
                <div class="line2">蓝领人力资源行业撮合担保交易系统。</div>
                <div class="line3"><a href="https://www.gwyapp.com/ydb/">现在去用</a></div>
            </div>
        </div>
    </div>
     
    <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">核心功能</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title"></div>
            <div class="l-body">
                <div class="p14_01_c">
                    <ul>
                        <li data-aos="fade-right" data-aos-delay="300" >
                            <img src="@/assets/images/product/yundanbao/fadan-icon.png" class="velocity-animating" style="transform: rotateZ(2.00107deg);">
                            <div class="p8_02_c jfa_01c_c">
                                <em class="em1">
                                    <h3 class="sp1">在线发单</h3>
                                    <span class="sp2">发布您的批量招人/供人需求，让资源主动找上门</span>

                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-right" data-aos-delay="600" >
                            <img src="@/assets/images/product/yundanbao/jiedan-icon.png" class="" style="transform: rotateZ(0deg);">
                            <div class="p8_02_c jfa_01c_c">
                                <em class="em1">
                                    <h3 class="sp1">在线接单</h3>
                                    <span class="sp2">用工资源在线合同对接平台担保，解决交易难题</span>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="900" >
                            <img src="@/assets/images/product/yundanbao/fuwu-icon.png">
                            <div class="p8_02_c jfa_01c_c">
                                <em class="em1">
                                    <h3 class="sp1">3715服务标准</h3>
                                    <span class="sp2">3天属地、7天省内、15天省外的快速招聘</span>
                                </em>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="1200" >
                            <img src="@/assets/images/product/yundanbao/zhishu-icon.png">
                            <div class="p8_02_c jfa_01c_c">
                                <em class="em1">
                                    <h3 class="sp1">价格指数</h3>
                                    <span class="sp2">不同行业不同地区的合作单价趋势</span>
                                </em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>
<!--分隔符-->
     <section>
        <div class="act_x">
            <div>
                <div class="wrap rly_01">
                    <ul>
                        <li class="l-body danbao-con2" style="">
                            
                           <div class="sfl_02_cell sfl_02_img" style="">
                                <img style="max-width:100%"  src="@/assets/images/product/yundanbao/possible.png" alt="">
                            </div>
                            <div class="sfl_02_cell sfl_02_txt" style="">
                                <h3 class="p1">无限可能</h3>
                                <p class="p2">
                                    <i>
                                    <span class="circle">•</span>
                                    从此标准化发单、接单。
                                    </i>
                                    <i>
                                    <span class="circle">•</span>
                                    无论您是劳务公司、还是工厂，亦或是工头，也不论 您是找厂，还是找人，云单保的服务始终如一。
                                    </i>
                
                                </p>
                            </div>
                            
                        </li>
                        <li class="l-body danbao-con2">
                            
                               
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">不止数字</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        更是对时效的承诺。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        实现3天属地、7天省内、15天省外的 快速招聘，可量化的人力资源解决方案。
                                        </i>
                                    </p>
                                </div>
                                 <div class="sfl_02_cell sfl_02_img sfl_02_img_wap">
                                    <img src="@/assets/images/product/yundanbao/3715.png" alt="">
                                </div>
                            
                        </li>
                        <li class="l-body danbao-con2">
                            
                               <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/yundanbao/editor.png" alt="">
                                </div>
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">多端查看编辑</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        订单无缝衔接。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        简单可信赖的分享协作工具，方便与您的团队无缝衔接，多端承接 订单，相信1+1＞2的力量。
                                        </i>
                                    </p>
                                </div>
                                 
                            
                        </li>
                        <li class="l-body danbao-con2">
                            
                              
                                <div class="sfl_02_cell sfl_02_txt">
                                    <h3 class="p1">信用评价</h3>
                                    <p class="p2">
                                        <i>
                                        <span class="circle">•</span>
                                        重新定义劳务价值。
                                        </i>
                                        <i>
                                        <span class="circle">•</span>
                                        依托多维的信用评价体系，使得违约责任货币化成为可能。 您的权益，我们帮您守护值。
                                        </i>
                                    </p>
                                </div>
                                <div class="sfl_02_cell sfl_02_img">
                                    <img src="@/assets/images/product/yundanbao/redim.png" alt="">
                                </div>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import ProductBanner from '../components/ProductBanner'
import Menu from '../components/Menu'
export default {
    name:'Danbao',
    data() {
      return {
        title:'云担保',
        bhref:'#'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        ProductBanner,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
