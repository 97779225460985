export const COMPANY_NAME = '江西职畅云信息科技有限公司';
export const ADDRESS='江西省九江市柴桑区庐山西路22号1018室';
export const ADDRESS_SUB='(人力资源和社会保障局办公室一楼)';
export const SHORTNAME='职畅云';
export const LINKNAME='高辉';
export const EMAIL='zhichangcloud@163.com';
export const ICP_BEIAN='赣ICP备:2021005040号-1';
export const ICP_ANBEI='赣公网安备36042102000162';
export const ZUOJI='0792-8798916';
export const SERVE_URL='https://www.zhichangcloud.com';
export const PROXY_URL='https://www.zhichangcloud.com';   //代理服务器