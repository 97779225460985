<template>
  <div style="width:100%">
    <header id="header_box">
        <div id="header">
          <div class="header-left" @click="goBack">
            <img class="back-img"  src="@/assets/images/back-icon.png" alt="menu_img">
          </div>
          <div class="header_menu_box">
              {{title}}
          </div>
          <div class="header-right" @click="showMenu">
            <img class="menu_img menu"   src="@/assets/images/menu.png" alt="menu_img">
          </div>
            
        </div>
    </header>
     <!-- 侧边菜单栏 -->
    <div  class="dh_menu" :class="menuShow?'show':''">
      <div class="side-shade" @click="hideMenu"></div>
      <div class="sidewrap">
        <ul>
          <li>
            <router-link :to="{path:'/'}" class="ico1">
              <img src="@/assets/images/menu/home-icon.png">
              首页
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/product'}" class="ico2">
              <img src="@/assets/images/menu/product-icon.png" />
              产品服务
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/demon'}" class="ico2">
              <img src="@/assets/images/menu/yanshi-icon.png" />
              在线演示
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/partner'}" class="ico1">
              <img src="@/assets/images/menu/kehu-icon.png" />
              合作客户
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/about'}" class="ico1">
              <img src="@/assets/images/menu/contact-icon.png" />
              联系我们
            </router-link>
          </li>
          <li>
            <router-link :to="{path:'/jiameng'}" class="ico2">
              <img src="@/assets/images/menu/jiameng-icon.png" />
              合作加盟
            </router-link>
          </li>   
        </ul>
      </div>
    </div>
    <!-- 侧边菜单栏end-->
  </div>
 
</template>

<script>
export default {
  name: 'Menu',
  props: {
    title:String
  },
  data(){
    return {
      menuShow:false,
    }
  },
  methods:{
    showMenu(){
        this.menuShow=true;
    },
    hideMenu(){
      this.menuShow=false;
    },
    goBack(){
      this.$router.back();
    }
  },
  computed:{
    
  }
}
</script>
<style scoped>
</style>
